var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model)?_c('div',{staticClass:"page-container"},[_c('Lightbox',{on:{"input":function($event){return _vm.$refs.inin.activityRestored()}},model:{value:(_vm.activeImage),callback:function ($$v) {_vm.activeImage=$$v},expression:"activeImage"}}),_c('div',{class:[{ fullscreen: _vm.fullscreen }, 'interactions']},[_c('div',{staticClass:"panel-left"},[(!_vm.fullscreen)?_c('TheNavbar',{staticClass:"navbar",attrs:{"fallback":""}}):_vm._e(),(!_vm.fullscreen)?_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.model.name))]),_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.model.producer))]),_c('div',{staticClass:"line"},[_vm._v("Kod: "+_vm._s(_vm.model.code))])]):_vm._e(),_c('div',{staticClass:"player"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRate),expression:"showRate"}],staticClass:"rate-video"},[_c('RateVideo')],1),(_vm.numOfSteps)?_c('div',{staticClass:"stepInfo"},[_vm._v(_vm._s(_vm.stepInfo))]):_vm._e(),_c('CortonaLoader',{ref:"inin",attrs:{"modelPath":_vm.model.modelPath},on:{"event":_vm.updateData}}),_c('div',{staticClass:"controls"},[(_vm.stepMode)?_c('img',{staticClass:"button small",attrs:{"src":_vm.ico.prev},on:{"click":function($event){return _vm.$refs.inin.previousStep()}}}):_vm._e(),_c('img',{staticClass:"button",attrs:{"src":_vm.ico.play[_vm.playing]},on:{"click":function($event){return _vm.$refs.inin.switchPlaying()}}}),(_vm.stepMode)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Następny krok',
              placement: 'top',
              classes: ['next-step', 'jumping'],
              show: _vm.tutorial.nextStep,
              trigger: 'manual',
              container: false
            }),expression:"{\n              content: 'Następny krok',\n              placement: 'top',\n              classes: ['next-step', 'jumping'],\n              show: tutorial.nextStep,\n              trigger: 'manual',\n              container: false\n            }"}],staticClass:"button small",attrs:{"src":_vm.ico.next},on:{"click":function($event){return _vm.$refs.inin.nextStep()}}}):_vm._e(),_c('VueSlider',{staticClass:"slider",attrs:{"max":_vm.sliderMax,"marks":!_vm.stepMode && _vm.sliderMarks,"duration":0,"tooltip":"none","silent":"","hideLabel":"","processStyle":{ 'background-color': '#f67620' },"railStyle":{ height: '6px', 'background-color': '#dce2ea' },"stepStyle":{ 'background-color': '#f67620' },"stepActiveStyle":{ 'background-color': '#dce2ea' },"dotStyle":{
              width: '20px',
              height: '20px',
              'box-shadow': '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
              border: 'solid 6px #f67620',
              'background-color': '#ffffff',
              'margin-top': '-2px'
            }},on:{"dragging":_vm.seekByProgress},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.formattedTime))]),(_vm.stepMode)?_c('img',{staticClass:"button",attrs:{"src":_vm.ico.loop[_vm.repeat]},on:{"click":function($event){return _vm.$refs.inin.switchRepeat()}}}):_vm._e(),_c('img',{staticClass:"button",attrs:{"src":_vm.ico.steps[_vm.stepMode]},on:{"click":function($event){return _vm.$refs.inin.switchStepMode()}}}),_c('img',{staticClass:"button",attrs:{"src":_vm.ico.fullscreen[_vm.fullscreen]},on:{"click":function($event){return _vm.toggleFullscreen()}}})],1)],1)],1),_c('ModelStepItems',{staticClass:"panel-right",attrs:{"currentStepItems":_vm.currentStepItems},on:{"click":_vm.activateLightbox}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }