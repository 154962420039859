<template>
  <div v-if="value" class="lightbox" @click="$emit('input', null)">
    <img :src="value.image" />
  </div>
</template>

<script>
export default {
  props: {
    value: Object
  }
}
</script>

<style lang="stylus" scoped>
.lightbox {
  display: block;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);

  >img {
    max-width: 90%;
    max-height: 80%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
  }
}
</style>
