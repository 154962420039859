<template>
  <div v-if="currentStepItems" class="items">
    <div v-if="currentStepTools.length" class="tools">
      <div class="title">Narzędzia ({{ currentStepTools.length }})</div>
      <div class="content">
        <ModelToolTile
          v-for="(item, index) in currentStepTools"
          :tool="item"
          :key="item.type + index"
          @click="item => $emit('click', item)"
        />
      </div>
    </div>
    <div v-if="currentStepParts.length" class="parts">
      <div class="title">Części ({{ currentStepParts.length }})</div>
      <div class="scroll">
        <div class="content">
          <ModelPartTile
            v-for="(item, index) in currentStepParts"
            :part="item"
            :key="item.type + index"
            @click="item => $emit('click', item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModelToolTile from "@/components/ModelToolTile.vue"
import ModelPartTile from "@/components/ModelPartTile.vue"

export default {
  components: {
    ModelToolTile,
    ModelPartTile
  },
  props: {
    currentStepItems: {
      type: Array,
      required: true
    }
  },
  computed: {
    currentStepParts() {
      return this.currentStepItems.filter(i => i.type == "part")
    },
    currentStepTools() {
      return this.currentStepItems.filter(i => i.type == "tool")
    }
  }
}
</script>

<style lang="stylus" scoped>
.items {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: stretch;
  margin-left: 10px;
  min-height: 200px;

  @media screen and (min-width: 600px) {
    width: 160px;
  }

  @media screen and (min-width: 900px) {
    width: 320px;
  }

  >.tools {
    >.content {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
    }
  }

  >.parts {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    >.scroll {
      display: flex;
      flex: 1 1 auto;
      overflow-y: auto;
      position: relative;

      >.content {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;

        @media screen and (min-width: 600px) {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: left;
    margin: 10px 0px 10px 10px;
  }
}
</style>
