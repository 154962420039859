export default class Inin {
  constructor(cortona, canvas, xmlPath, external) {
    this.cortona = cortona
    this.canvas = canvas
    this.xmlPath = xmlPath
    this.external = external

    this.xmlModel = xmlPath + ".xml"
    this.xmlInteractivity = xmlPath + ".interactivity.xml"

    this.playing = false
    this.repeat = false
    this.stepMode = false
    this.currentStepIndex = 0

    this.config = {
      baseUrl: "spec/",
      src: this.xmlInteractivity,
      totalMemory: 128
    }
    this.drawing = {
      element: this.canvas,
      features:
        this.cortona.app.ENABLE_NAVIGATION_FIT_TO_OBJECT |
        this.cortona.app.DRAWING_HOTSPOT_HIGHLIGHT_SOLID |
        this.cortona.app.TRANSPARENT_BACKGROUND
    }
    this.loadEngine()
  }

  setExternal(external) {
    this.external = external
  }

  playPause() {
    if (this.playing) this.cortona.app.procedure.pause()
    else this.cortona.app.procedure.play()
  }

  activityInterrupted() {
    if (this.playing) {
      this.activityInterruptedPause = true
      this.cortona.app.procedure.pause()
    }
  }

  activityRestored() {
    if (this.activityInterruptedPause) {
      this.activityInterruptedPause = false
      this.cortona.app.procedure.play()
    }
  }

  seek(time) {
    this.cortona.app.procedure.setPlayPosition(time, false)
    this.cortona.app.procedure.requestPlayerState()
  }

  switchStepMode() {
    this.stepMode ? this.disableStepMode() : this.enableStepMode()
    return this.stepMode
  }

  enableStepMode() {
    this.stepMode = true
    this.stepRoll(0)
  }

  disableStepMode() {
    this.stepMode = false
    this.cortona.app.procedure.setPlayRange()
    this.notifyRangeChanged()
  }

  isStepModeEnabled() {
    return this.stepMode
  }

  switchRepeat() {
    this.repeat ? this.disableRepeat() : this.enableRepeat()
    return this.repeat
  }

  enableRepeat() {
    if (this.stepMode) this.repeat = true
  }

  disableRepeat() {
    this.repeat = false
  }

  isRepeatEnabled() {
    return this.repeat
  }

  async loadEngine() {
    this.addListeners()
    await this.cortona.use(
      "core",
      this.cortona.expand({}, this.config, this.drawing)
    )
    this.cortona.use("require")
    this.cortona.use("touch")
    this.cortona.use("drawing")
    this.cortona.use("Cortona3DSoloTouch")
    //await this.cortona.app.loadCompanionFile(this.xmlInteractivity)
    this.cortona.touch.options.FEATURE_PICK_TOPMOST = false
  }

  hoverItem(docId, enable) {
    if (docId) {
      this.cortona.app.procedure.interactivity
        .getObjectNamesByDocId(docId)
        .map(this.cortona.app.getObjectWithName)
        .forEach(objectId => {
          let color = this.cortona.app.PROPERTY_EMISSIVE_COLOR
          enable
            ? this.cortona.app.setObjectPropertyf(
                objectId,
                color,
                true,
                0.5,
                0.5,
                0.5
              )
            : this.cortona.app.restoreObjectProperty(objectId, color, true)
        })
    }
  }

  getDocIdForObject(e) {
    let getDocIdByObjectName = this.cortona.app.procedure.interactivity
      .getDocIdByObjectName
    for (
      var t = getDocIdByObjectName(this.cortona.app.getObjectName(e));
      !t && e;

    )
      (e = this.cortona.app.getParentObject(e)),
        (t = getDocIdByObjectName(this.cortona.app.getObjectName(e)))
    return t
  }

  getStepsData() {
    let stepIds = this.cortona.app.procedure.interactivity.getProcedureItemInfo(
      this.cortona.app.procedure.interactivity.getProcedureId()
    ).children
    this.stepsData = []
    for (var i = stepIds.length - 1; i >= 0; --i) {
      let step = stepIds[i]
      this.cortona.app.procedure.setPlayRange(step)
      this.stepsData.unshift({
        step: step,
        start: this.cortona.app.procedure.getPlayableRangeStartTime(),
        stop: this.cortona.app.procedure.getPlayableRangeStopTime()
      })
    }
    this.cortona.app.procedure.setPlayRange()
    this.cortona.app.procedure.seekToSubstep(this.stepsData[0].step)
    this.onEverythingLoaded()
  }

  mod(n, m) {
    return ((n % m) + m) % m
  }

  stepRoll(indexOffset) {
    let stepChanged = indexOffset != 0
    // let wasPlaying = this.playing
    let chosenIndex = this.currentStepIndex + indexOffset
    let correctedIndex = this.mod(chosenIndex, this.stepsData.length)
    let chosenStepData = this.stepsData[correctedIndex].step
    if (stepChanged) this.cortona.app.procedure.seekToSubstep(chosenStepData)
    this.cortona.app.procedure.setPlayRange(chosenStepData)
    this.notifyRangeChanged()
    if (stepChanged) this.cortona.app.procedure.play()
  }

  previous() {
    this.stepRoll(-1)
  }

  next() {
    this.stepRoll(1)
  }

  notifyRangeChanged() {
    if (this.external) {
      this.external.rangeChanged(
        this.cortona.app.procedure.getPlayableRangeStartTime(),
        this.cortona.app.procedure.getPlayableRangeStopTime()
      )
      this.cortona.app.procedure.requestPlayerState()
    }
  }

  addListeners() {
    this.cortona.on("app.didFinishLoadDocument", (e, t) => {
      this.getStepsData()
    })
  }

  getTickMarks() {
    return this.stepsData.map(s => s.start)
  }

  getCurrentStepNum() {
    return this.currentStepIndex + 1
  }

  getCurrentStepId() {
    return this.stepsData[this.currentStepIndex].step
  }

  getNumOfSteps() {
    return this.stepsData.length
  }

  getStartTime() {
    return this.cortona.app.procedure.getPlayableRangeStartTime()
  }

  getEndTime() {
    return this.cortona.app.procedure.getPlayableRangeStopTime()
  }

  onEverythingLoaded() {
    this.notifyRangeChanged()
    this.enableStepMode()
    if (this.external) this.external.engineLoaded()

    this.cortona.on("app.procedure.didEnterSubstepWithName", substepId => {
      let stepId = this.cortona.app.procedure.interactivity.getProcedureItemInfo(
        substepId
      ).parentStep
      this.currentStepIndex = this.stepsData.findIndex(
        stepData => stepData.step === stepId
      )
      if (this.external)
        this.external.stepChanged(
          this.getCurrentStepNum(),
          this.getNumOfSteps(),
          this.getCurrentStepId()
        )
    })

    this.cortona.on("touch.didObjectEnter", (e, t) => {
      var docId = this.getDocIdForObject(e)
      docId && this.cortona.dispatch("procedure.didObjectEnter", docId)
    })
    this.cortona.on("touch.didObjectOut", (e, t) => {
      var docId = this.getDocIdForObject(e)
      docId && this.cortona.dispatch("procedure.didObjectOut", docId)
    })
    this.cortona.on("touch.didObjectClick", (e, t) => {
      var docId = this.getDocIdForObject(e)
      docId && this.cortona.dispatch("procedure.didObjectClick", docId)
    })
    this.cortona.on("touch.didPointerDown", (x, y) => {
      if (this.playing) {
        this.cortona.app.procedure.pause()
      } else {
        this.resumePossible = { x, y }
        setTimeout(() => (this.resumePossible = false), 500)
      }
    })
    this.cortona.on("touch.didPointerUp", (x, y) => {
      if (
        !this.playing &&
        this.resumePossible &&
        this.resumePossible.x == x &&
        this.resumePossible.y == y
      ) {
        this.resumePossible = false
        this.cortona.app.procedure.play()
      }
    })
    this.cortona.on("procedure.didObjectClick", docId => {
      this.hoverItem(docId, true)
      setTimeout(() => this.hoverItem(docId, false), 500)
    })

    this.cortona.on("app.procedure.didChangePlayerState", (time, flags) => {
      let wasPlaying = this.playing
      this.playing = flags & 1
      if (
        this.stepMode &&
        this.repeat &&
        this.cortona.app.procedure.getPlayableRangeStopTime() <= time &&
        wasPlaying &&
        !this.playing
      )
        window.setTimeout(() => this.cortona.app.procedure.play(), 10)
      // console.log("ININ: this.playing: " + !!this.playing)
      if (this.external) this.external.progressChanged(time, !!this.playing)
    })
  }
  logAllEvents() {
    let possibleEvents = [
      "app.didAttachDocument",
      "app.didCallContextMenu",
      "app.didClickHyperlink",
      "app.didFailLoadDocument",
      "app.didFinishLoadDocument",
      "app.drawing.didCallContextMenu",
      "app.drawing.didChangeScale",
      "app.drawing.didEnterHotspot",
      "app.drawing.didFailLoadDrawing",
      "app.drawing.didFinishLoadDrawing",
      "app.drawing.didHoverHotspot",
      "app.drawing.didLeaveHotspot",
      "app.drawing.didSelectHotspot",
      "app.drawing.didStartLoadDrawing",
      "app.firstFrameDidArrive",
      "app.ipc.didChangeItemVisibility",
      "app.ipc.didDrawingDisplayMode",
      "app.ipc.didHoverItem",
      "app.ipc.didSelectItem",
      "app.ipc.didSelectSheet",
      "app.ipc.dpl.didCallContextMenu",
      "app.ipc.dpl.didHoverRow",
      "app.ipc.dpl.didSelectRow",
      "app.ipc.dpl.didSetupRow",
      "app.ipc.dpl.didSetupTable",
      "app.ipc.dpl.willSetupTable",
      "app.onProgress",
      "app.onReady",
      "app.procedure.didChangePlayerState",
      "app.procedure.didEnterSubstep",
      "app.procedure.didEnterSubstepWithName",
      "app.procedure.didFireEvent",
      "app.willStartNavigateInScene",
      "core.didChangeLayout",
      //"core.didDrawAnimationFrame",
      "core.onError",
      "core.onLoad",
      "core.onRuntimeInitialized",
      "touch.didObjectClick",
      "touch.didObjectEnter",
      "touch.didObjectMove",
      "touch.didObjectOut",
      "touch.didPointerDown",
      "touch.didPointerEnter",
      "touch.didPointerMove",
      "touch.didPointerOut",
      "touch.didPointerUp"
    ]
    possibleEvents.forEach(event => {
      this.cortona.on(event, () => {
        let args = Array.prototype.slice.call(arguments)
        console.log(event + "(" + args.join(", ") + ")")
      })
    })
  }
}
