import { render, staticRenderFns } from "./RateVideo.vue?vue&type=template&id=ce3ed452&scoped=true&"
import script from "./RateVideo.vue?vue&type=script&lang=js&"
export * from "./RateVideo.vue?vue&type=script&lang=js&"
import style0 from "./RateVideo.vue?vue&type=style&index=0&id=ce3ed452&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce3ed452",
  null
  
)

export default component.exports