<template>
  <div class="tile_container" @click="$emit('click', tool)">
    <div class="tile">
      <img class="icon" :src="tool.image" />
    </div>
    <span class="label">{{ tool.name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    tool: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="stylus">
.tile_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  width: 92px;
  cursor: pointer;
}

.tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 70px;
  border: solid 2px transparent;
  box-shadow: 1px 2px 3px 0 #000;
  transition-duration: 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.icon {
  max-width: 80%;
  max-height: 80%;
}

.label {
  margin-top: 15px;
  color: white;
  font-size: 12px;
}
</style>
