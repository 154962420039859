<template>
  <div class="model">
    <div class="text">
      <p>Gratulacje, dotarłeś do końca!</p>
      <hr class="line" />
      <p>Jak oceniasz tę instrukcję?</p>
    </div>
    <div class="stars">
      <star-rating
        :increment="1"
        :max-rating="5"
        inactive-color="white"
        active-color="rgb(236,120,40)"
        border-color="#292929"
        :border-width="2"
        :star-size="40"
        :show-rating="false"
        @rating-selected="activeButton = true"
      >
      </star-rating>
    </div>
    <div>
      <div>
        <textarea
          maxlength="500"
          cols="30"
          rows="3"
          placeholder="Zostaw komentarz"
          class="inputForm"
        ></textarea>
      </div>
      <button
        :disabled="!activeButton"
        @click="$router.push('/')"
        class="button"
      >
        Wyślij
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RateVideo",
  data: function() {
    return {
      activeButton: false
    }
  },
  components: {
    StarRating
  }
}
import StarRating from "vue-star-rating"
</script>

<style scoped>
.model {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #292929;
  border-radius: 10px;
  z-index: 100;
  padding: 5px 20px 20px 20px;
}
.text {
  color: white;
  font-size: 1.4em;
}
hr.line {
  border: 1px ridge white;
}
.stars {
  margin-top: -15px;
  margin-bottom: 7px;
}
.inputForm {
  font-family: Ubuntu;
  height: 40px;
  margin: 7px 0px;
  padding: 3px;
  border-radius: 10px;
  border: none;
  outline: none;
  resize: none;
  overflow: auto;
  text-align: center;
}
.inputForm:hover {
  background-color: cornsilk;
}
.button {
  position: absolute;
  right: -25px;
  padding: 5px 30px;
  border: 4px solid white;
  border-radius: 20px;
  background-color: rgb(236, 120, 40);
  color: white;
  font-size: 1em;
  z-index: 100;
  cursor: pointer;
}
button:disabled {
  background-color: #cccccc;
  color: #666666;
}
button:hover {
  padding: 6px 32px;
  border-width: 2px;
}
</style>
