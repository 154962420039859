<template>
  <div v-if="model" class="page-container">
    <Lightbox v-model="activeImage" @input="$refs.inin.activityRestored()" />
    <div :class="[{ fullscreen: fullscreen }, 'interactions']">
      <div class="panel-left">
        <TheNavbar v-if="!fullscreen" class="navbar" fallback />
        <div v-if="!fullscreen" class="header">
          <div class="title">{{ model.name }}</div>
          <div class="line">{{ model.producer }}</div>
          <div class="line">Kod: {{ model.code }}</div>
        </div>
        <!-- <div class="title">Instrukcja</div> -->
        <div class="player">
          <div v-show="showRate" class="rate-video">
            <RateVideo> </RateVideo>
          </div>
          <div v-if="numOfSteps" class="stepInfo">{{ stepInfo }}</div>
          <CortonaLoader
            :modelPath="model.modelPath"
            ref="inin"
            @event="updateData"
          />
          <div class="controls">
            <img
              v-if="stepMode"
              class="button small"
              @click="$refs.inin.previousStep()"
              :src="ico.prev"
            />
            <img
              class="button"
              @click="$refs.inin.switchPlaying()"
              :src="ico.play[playing]"
            />
            <img
              v-if="stepMode"
              class="button small"
              @click="$refs.inin.nextStep()"
              :src="ico.next"
              v-tooltip="{
                content: 'Następny krok',
                placement: 'top',
                classes: ['next-step', 'jumping'],
                show: tutorial.nextStep,
                trigger: 'manual',
                container: false
              }"
            />
            <VueSlider
              class="slider"
              v-model="progress"
              :max="sliderMax"
              :marks="!stepMode && sliderMarks"
              :duration="0"
              tooltip="none"
              silent
              hideLabel
              @dragging="seekByProgress"
              :processStyle="{ 'background-color': '#f67620' }"
              :railStyle="{ height: '6px', 'background-color': '#dce2ea' }"
              :stepStyle="{ 'background-color': '#f67620' }"
              :stepActiveStyle="{ 'background-color': '#dce2ea' }"
              :dotStyle="{
                width: '20px',
                height: '20px',
                'box-shadow': '0 5px 10px 0 rgba(0, 0, 0, 0.2)',
                border: 'solid 6px #f67620',
                'background-color': '#ffffff',
                'margin-top': '-2px'
              }"
            ></VueSlider>
            <div class="time">{{ formattedTime }}</div>
            <img
              v-if="stepMode"
              class="button"
              @click="$refs.inin.switchRepeat()"
              :src="ico.loop[repeat]"
            />
            <img
              class="button"
              @click="$refs.inin.switchStepMode()"
              :src="ico.steps[stepMode]"
            />
            <img
              class="button"
              @click="toggleFullscreen()"
              :src="ico.fullscreen[fullscreen]"
            />
          </div>
        </div>
      </div>

      <ModelStepItems
        class="panel-right"
        :currentStepItems="currentStepItems"
        @click="activateLightbox"
      />
    </div>
  </div>
</template>

<script>
import CortonaLoader from "@/components/CortonaLoader.vue"
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/default.css"
import fetchParts from "@/utils/fetchParts.js"
import { mapActions } from "vuex"
import ModelStepItems from "@/components/ModelStepItems.vue"
import TheNavbar from "@/components/TheNavbar.vue"
import Lightbox from "@/components/Lightbox.vue"
import fullscreen from "vue-fullscreen"
import Vue from "vue"
import RateVideo from "@/components/RateVideo.vue"

Vue.use(fullscreen)

export default {
  name: "Model",
  props: {
    id: String
  },
  components: {
    RateVideo,
    CortonaLoader,
    VueSlider,
    ModelStepItems,
    TheNavbar,
    Lightbox
  },
  data() {
    return {
      model: null,
      stepParts: null,
      step: 0.01,
      time: 0,
      playing: false,
      stepMode: true,
      repeat: false,
      marks: [],
      rangeStart: 0,
      rangeEnd: 0,
      currentStepNum: 0,
      currentStepId: null,
      numOfSteps: 0,
      activeImage: null,
      fullscreen: false,
      ico: {
        prev: require("@/assets/controls/ic_prev_step.svg"),
        play: {
          true: require("@/assets/controls/ic_pause.svg"),
          false: require("@/assets/controls/ic_play.svg")
        },
        next: require("@/assets/controls/ic_next_step.svg"),
        loop: {
          true: require("@/assets/controls/ic_loop_on.svg"),
          false: require("@/assets/controls/ic_loop_off.svg")
        },
        steps: {
          true: require("@/assets/controls/ic_steps_on.svg"),
          false: require("@/assets/controls/ic_steps_off.svg")
        },
        fullscreen: {
          true: require("@/assets/controls/fullscreen_exit.svg"),
          false: require("@/assets/controls/fullscreen.svg")
        }
      }
    }
  },
  methods: {
    ...mapActions(["fetchAllModels"]),
    updateData(name, value) {
      this[name] = value
    },
    seekByProgress(progress) {
      let time = this.rangeStart + progress * this.step
      this.$refs.inin.seekTime(time)
    },
    async fetchData() {
      console.log("fetching data")
      if (!this.$store.state.models.length) {
        await this.fetchAllModels()
        console.log("after call to fetchAll")
      }
      this.model = this.$store.state.models[this.id]
      this.stepParts = await fetchParts(`/models/${this.model.modelPath}.xml`)
      console.log("after call to fetchParts", this.stepParts)
    },
    activateLightbox(item) {
      this.$refs.inin.activityInterrupted()
      this.activeImage = item
    },
    toggleFullscreen() {
      this.$fullscreen.toggle(document.body, {
        wrap: false,
        callback: f => {
          this.fullscreen = f
        }
      })
    }
  },
  updated() {
    this.$refs.inin.resizeFrame()
  },
  created() {
    this.fetchData()
  },
  computed: {
    tutorial() {
      return {
        nextStep: this.currentStepNum === 1 && this.time === this.rangeEnd
      }
    },
    progress: {
      get() {
        return Math.floor((this.time - this.rangeStart) / this.step)
      },
      set(value) {
        this.seekByProgress(value)
      }
    },
    sliderMax() {
      return Math.ceil((this.rangeEnd - this.rangeStart) / this.step)
    },
    showRate() {
      if (this.time > this.rangeEnd && this.currentStepNum == this.numOfSteps) {
        this.$refs.inin.setShow()
        return true
      }
      return false
    },
    sliderMarks() {
      return this.marks.map(m => Math.floor((m - this.rangeStart) / this.step))
    },
    formattedTime() {
      let minutes = String(Math.floor(this.time / 60)).padStart(2, "0")
      let seconds = String(Math.floor(this.time % 60)).padStart(2, "0")
      return `${minutes}:${seconds}`
    },
    stepInfo() {
      return `Krok ${this.currentStepNum} z ${this.numOfSteps}`
    },
    currentStepItems() {
      // console.log("current step: ", this.currentStepId)
      return (
        (this.stepParts &&
          this.currentStepId &&
          this.stepParts.find(s => s.id == this.currentStepId).parts) ||
        []
      )
    }
  }
}
</script>

<style lang="stylus" scoped>
.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  background-color: white;
  height: 50px;
  border-radius: 0px 0px 10px 10px;
  flex: 0 0 auto;
  box-shadow: 0px -4px 13px 0px rgba(0, 0, 0, 0.1);
  padding: 0px 10px;

  >* {
    padding: 10px;
    flex: 0 0 auto;
  }

  >.button {
    color: #f67620;
    cursor: pointer;
    height: 16px;
  }

  >.small {
    height: 12px;
  }

  >.time {
    color: #787b7f;
  }

  >.slider {
    margin: 0px 10px;
    flex: 1 1 auto;

    .vue-slider-mark-step {
      background-color: #F67620;
    }
  }
}
.panel-left {
  display: flex;
  flex-direction: column;
  flex: 10 0 auto;
  align-items: stretch;
  padding-bottom: 10px;

  >.title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: left;
    margin: 20px 0px 20px 10px;
  }
}

.player {
  display: flex;
  flex: 10 0 auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #fefefe;
  min-width: 300px;
  min-height: 300px;

  >.stepInfo {
    padding: 1rem;
    position: absolute;
    font-size: 1.4rem;
    z-index: 10;
    color: #444;
    background: rgba(255,255,255,0.9);
    border-radius: 10px 0 10px 0;
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
  }
  >.rate-video {
    position: absolute;
    align-self center;
    margin-top: 15vh;
    z-index: 11;
  }
}

.header {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  >.title {
    font-size: 1.5em;
  }

  >.line {
    color: #b0b4b9;
  }

  >.title, >.line {
    line-height: 1em;
  }
}

.interactions {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 2px 2px 2px;
  min-width: 350px;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    margin: 0px 30px 30px 30px;
  }

  &.fullscreen {
    margin: 0px 2px 2px 2px;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
}
</style>
