<template>
  <div id="loading">
    <vue-awesome-progress
      v-if="progress && progress < 100"
      circle-color="#dce2ea"
      line-color="#f67620"
      point-color="#f67620"
      :circle-width="4"
      :line-width="8"
      :circle-radius="70"
      :point-radius="0"
      :duration="2"
      :start-deg="270"
      :percentage="progress"
      :animated="true"
      easing="0.42,0,1,1"
    />
    <div v-else class="middle">
      <div class="lds-hourglass"></div>
    </div>
  </div>
</template>

<script>
import Inin from "@/engine/inin.js"
import VueAwesomeProgress from "vue-awesome-progress"

export default {
  name: "CortonaLoader",
  props: {
    modelPath: String
  },
  components: {
      VueAwesomeProgress
  },
  data() {
    return {
      xmlPath: "/models/" + this.modelPath,
      loaded: false,
      fromCache: false,
      rateVideoClosed: true,
      progress: 0
    }
  },
  destroyed() {
    if (this.loaded) {
      this.inin().cortona.app.procedure.pause()
      this.inin().cortonaFrame.style.display = "none"
      this.$window.removeEventListener("resize", this.resizeFrame)
      this.$window.removeEventListener("keydown", this.onKeyPress)
    } else {
      this.inin().cortonaFrame.style.display = "none"
      let frame = this.inin().cortonaFrame
      if (frame) frame.remove()
      this.$ininCache[this.xmlPath] = undefined
    }
  },
  methods: {
    setShow() {
      this.rateVideoClosed = false
    },
    resizeFrame() {
      let inin = this.inin()
      if (inin && inin.cortonaFrame) {
        let loading = this.$window.document.getElementById("loading")
        let rect = loading.getBoundingClientRect()
        let brect = this.$window.document.body.getBoundingClientRect()
        let frame = inin.cortonaFrame
        frame.style.top = rect.top - brect.top + "px"
        frame.style.left = rect.left - brect.left + "px"
        frame.style.width = rect.width + "px"
        frame.style.height = rect.height + "px"
      }
    },
    getById(id) {
      return this.$window.document.getElementById(id)
    },
    inin() {
      return this.$ininCache[this.xmlPath]
    },
    engineLoaded() {
      this.$emit("event", "marks", this.inin().getTickMarks())
      this.$transitionEvents.onceComplete(() => {
        this.showFrame()
      })
    },
    showFrame() {
      this.$window.addEventListener("resize", this.resizeFrame)
      this.resizeFrame()
      this.$window.document.getElementById("loading").style.visibility =
        "hidden"
      this.inin().cortonaFrame.style.display = "block"
      if (!this.fromCache) this.inin().cortona.app.procedure.play()
      this.loaded = true
    },
    createNewFrame() {
      let frames = this.getById("frames")
      let iframe = this.$window.document.createElement("iframe")
      iframe.src = "/iframe.html"
      iframe.sandbox = "allow-same-origin allow-scripts"
      iframe.style.overflow = "hidden"
      iframe.style.border = "none"
      iframe.onload = this.onFirstFrameLoad(iframe)
      frames.appendChild(iframe)
    },
    onFirstFrameLoad(iframe) {
      return () => {
        let cortonaWindow = iframe.contentWindow
        cortonaWindow.addEventListener('loadingProgress', event => {
          console.log(event)
          this.progress = event.detail
        })
        if (cortonaWindow && cortonaWindow.Cortona3DSolo) {
          let canvas = cortonaWindow.document.getElementById("canvas")
          this.$ininCache[this.xmlPath] = new Inin(
            cortonaWindow.Cortona3DSolo,
            canvas,
            this.xmlPath,
            this
          )
          // this.inin().logAllEvents()
          this.inin().cortonaFrame = iframe
          iframe.onload = null
        }
      }
    },
    prepareNewOrCachedFrame() {
      if (this.inin()) {
        this.fromCache = true
        this.inin().setExternal(this)
        this.dispatchEvents()
        this.engineLoaded()
      } else {
        this.createNewFrame()
      }
    },
    dispatchEvents() {
      this.$emit("event", "stepMode", this.inin().isStepModeEnabled())
      this.$emit("event", "repeat", this.inin().isRepeatEnabled())
      this.$emit("event", "currentStepNum", this.inin().getCurrentStepNum())
      this.$emit("event", "currentStepId", this.inin().getCurrentStepId())
      this.$emit("event", "numOfSteps", this.inin().getNumOfSteps())
      this.$emit("event", "rangeEnd", this.inin().getEndTime())
      this.$emit("event", "rangeStart", this.inin().getStartTime())
      this.$emit("event", "marks", this.inin().getTickMarks())
      this.inin().cortona.app.procedure.requestPlayerState()
    },
    progressChanged(time, isPlaying) {
      this.$emit("event", "time", time)
      this.$emit("event", "playing", isPlaying)
    },
    rangeChanged(start, end) {
      this.$emit("event", "rangeEnd", end)
      this.$emit("event", "rangeStart", start)
    },
    stepChanged(numCurrent, numAll, stepId) {
      this.$emit("event", "currentStepNum", numCurrent)
      this.$emit("event", "currentStepId", stepId)
      this.$emit("event", "numOfSteps", numAll)
    },
    switchStepMode() {
      if (this.loaded)
        this.$emit("event", "stepMode", this.inin().switchStepMode())
    },
    switchRepeat() {
      if (this.loaded) this.$emit("event", "repeat", this.inin().switchRepeat())
    },
    switchPlaying() {
      if (this.loaded) this.inin().playPause()
    },
    previousStep() {
      if (this.loaded) this.inin().previous()
    },
    nextStep() {
      if (this.loaded) this.inin().next()
    },
    seekTime(time) {
      if (this.loaded) this.inin().seek(time)
    },
    activityInterrupted() {
      if (this.loaded) this.inin().activityInterrupted()
    },
    activityRestored() {
      if (this.loaded) this.inin().activityRestored()
    },
    onKeyPress(event) {
      if (event.key === " " && this.rateVideoClosed) {
        this.switchPlaying()
      } else if (event.key === "ArrowLeft" && this.rateVideoClosed) {
        this.previousStep()
      } else if (event.key === "ArrowRight" && this.rateVideoClosed) {
        this.nextStep()
      }
    }
  },
  mounted() {
    this.prepareNewOrCachedFrame()
    this.$window.addEventListener("keydown", this.onKeyPress)
  }
}
</script>

<style lang="stylus" scoped>
#loading {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
}

.middle {
  flex: 0 0 auto;
}

.lds-hourglass {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  border: 50px solid #ff906a;
  border-color: #ff906a transparent #ff906a transparent;
  animation: lds-hourglass 2.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}
</style>
