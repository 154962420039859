<template>
  <div class="tile_container tile" @click="$emit('click', part)">
    <img class="icon" :src="part.image" />
    <span class="label">{{ part.name }}</span>
    <span class="quantity">{{ `x${part.quantity}` }}</span>
  </div>
</template>

<script>
export default {
  props: {
    part: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="stylus">
.tile_container {
  margin: 10px;
  width: 130px;
  height: 130px;
  border: solid 2px transparent;
  position: relative;
  cursor: pointer;
  box-shadow: 1px 2px 3px 0 #000;
  transition-duration: 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  >* {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white, 0px 0px 10px white;
  }
}

.icon {
  top: 5%;
  max-width: 90%;
  max-height: 90%;
}

.label {
  top: 5%;
  color: #292929;
  font-size: 12px;
}

.quantity {
  color: #292929;
  font-size: 20px;
  font-weight: bold;
  bottom: 0;
  right: 0;
  text-align: right;
  padding: 5px;
}
</style>
