import { get } from "axios"
const xml2js = require("xml2js")

async function parseString(value) {
  var parser = new xml2js.Parser()
  return await new Promise((resolve, reject) => {
    parser.parseString(value, function(err, result) {
      if (err) reject(err)
      else resolve(result)
    })
  })
}

function getObj(arrayedObject) {
  return arrayedObject ? arrayedObject[0] : null
}

function toInt(str) {
  let num = parseInt(str)
  return isNaN(num) ? undefined : num
}

export default async xmlPath => {
  let response = await get(xmlPath)
  let result = await parseString(response.data)
  let imgPrefix = xmlPath.slice(0, xmlPath.lastIndexOf("/")) + "/"
  let parts = result.rwi.bom.flatMap(b => b.part)
  let steps = result.rwi.job
    .flatMap(j => j.task)
    .map(t => ({
      id: t.$.id,
      title: getObj(t.title),
      parts: !t.para
        ? []
        : t.para
            .flatMap(p => p.xref)
            .filter(x => !!x)
            .filter(
              (x, i, arr) =>
                arr.findIndex(x2 => x2.$.xrefid === x.$.xrefid) === i //distinct
            )
            .map(x => {
              let partId = x.$.xrefid
              let part = parts.filter(p => p.$.id == partId)[0]
              let image = t.image && t.image.find(i => i.$.alt == partId)
              return {
                id: partId,
                image:
                  image && imgPrefix + image.$.href.replace(".cgm", ".svg"),
                name: getObj(part.desc),
                quantity: toInt(getObj(part.qty)),
                type: part.$.type == "resource" ? "tool" : "part"
              }
            })
    }))
  return steps
}
